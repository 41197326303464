<template>
<div class="photo-list-block ">
  <v-container>
      <v-row class="list">
        <v-col cols="6"
               sm="4"
               lg="3"
               class="img"
               v-for="image of blc.images.items"
               :key="image.uid"
        >
            <v-responsive :aspect-ratio="1/1">
              <ee09-picture
                  xs="250"
                  sm="400"
                  md="600"
                  lg="800"
                  xl="800"
                  compression="80"
                  :image-field="image.image"
              />
            </v-responsive>
        </v-col>
      </v-row>
  </v-container>
</div>
</template>

<script>
import BlockMixin from "@/Models/blocks/BlockMixin";
import Ee09Picture from "@/components/atoms/ee09-picture";

export default {
  name: "photo-list-block",
  components: {Ee09Picture},
  mixins:[BlockMixin],
  computed:{
    /**
     *
     * @return {PhotoListBlock}
     */
    blc(){
      return this.block;
    }
  }
}
</script>

<style lang="less">
.photo-list-block{
  .media-rounded{
    position: relative;
    overflow: hidden;
    .ee09-picture{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
  @media(min-width: @screen-md){
    .row.list{
      .img{
        >*{
          outline: 1px solid #111;
        }
      }
    }
  }


}
</style>