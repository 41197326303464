<template>
<picture v-if="img && img.isOk"
         class="ee09-picture">
  <source type="image/webp"
          :srcset="img.resize(xs,xs,null,bg,compression,'webp')+' 1x'"
          media="(max-width: 599px)">
  <source type="image/webp"
          :srcset="img.resize(sm,sm,null,bg,compression,'webp')+' 1x'"
          media="(min-width: 600px) and (max-width: 959px)">
  <source type="image/webp"
          :srcset="img.resize(md,md,null,bg,compression,'webp')+' 1x'"
          media="(min-width: 960px) and (max-width: 1263px)">
  <source type="image/webp"
          :srcset="img.resize(lg,lg,null,bg,compression,'webp')+' 1x'"
          media="(min-width: 1264px) and (max-width: 1903px)">
  <source type="image/webp"
          :srcset="img.resize(xl,xl,null,bg,compression,'webp')+' 1x'"
          media="(min-width: 1904px)">

    <source :srcset="img.resize(xs,xs,null,bg,compression,format)+' 1x'"
            media="(max-width: 599px)">
    <source :srcset="img.resize(sm,sm,null,bg,compression,format)+' 1x'"
            media="(min-width: 600px) and (max-width: 959px)">
    <source :srcset="img.resize(md,md,null,bg,compression,format)+' 1x'"
            media="(min-width: 960px) and (max-width: 1263px)">
    <source :srcset="img.resize(lg,lg,null,bg,compression,format)+' 1x'"
            media="(min-width: 1264px) and (max-width: 1903px)">
    <source :srcset="img.resize(xl,xl,null,bg,compression,format)+' 1x'"
            media="(min-width: 1904px)">

  <img
      :src="img.resize(md,md,null,bg,compression,format)"
      :style="`object-position:${img.x*100}% ${img.y*100}%`"
      :alt="img.alt"
      :title="img.title"/>
</picture>


</template>

<script>
export default {
  name: "ee09-picture",
  props:{
    imageField:{
      type:Object,
    },
    xs:{
      type:[Number,String],
      default:300
    },
    sm:{
      type:[Number,String],
      default:600
    },
    md:{
      type:[Number,String],
      default:700
    },
    lg:{
      type:[Number,String],
      default:800
    },
    xl:{
      type:[Number,String],
      default:900
    },
    compression:{
      type:[Number,String],
      default:60
    },
    format:{
      type:String,
      default:"jpg"
    },
    bg:{
      type:String,
      default:"000000"
    }
  },
  computed:{

    /**
     *
     * @return {ImageField}
     */
    img(){
      return this.imageField;
    }
  }

}
</script>

<style lang="less">
.ee09-picture{
  object-fit: cover;
  position: relative;
  img,source{
    position: relative;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
</style>