<template>
<div class="content-container"
     :class="{
        'full-height':fullHeight,
        'full-width':fullWidth,
        'enlarge-width':enlargeWidth
     }"><slot/></div>
</template>

<script>
export default {
  name: "content-container",
  props:{
    fullHeight:{
      type:Boolean,
      default:false
    },
    enlargeWidth:{
      type:Boolean,
      default:false
    },
    fullWidth:{
      type:Boolean,
      default:false
    }
  }
}
</script>

<style lang="less">
.content-container{
  flex-grow: 0;
  flex-shrink: 0;
  //outline: 1px solid blue;
  margin-left: auto;
  margin-right: auto;
  width: 75%;
  max-width: 1000px;
  @media(min-width: @screen-lg){
    width: 60%;
  }

  &.full-height{
    min-height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &.enlarge-width{
    //outline: 1px solid red;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    @media(min-width: @screen-lg){
      width: 80%;
      max-width: 1400px;
    }
  }
  &.full-width{
    min-width: calc( 100% - 40px );
    margin-left: 20px;
    margin-right: 20px;
  }


}


</style>