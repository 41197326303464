<template>
    <a :is="link.component" class="btn-arrow txt-link"
       :to="link.to"
       :href="link.toOrHref"
       :target="link.target"
       :color="color"
    >
      <span>{{link.label}}</span>
      <v-icon class="icon-size ico">iffdec-haut-droite</v-icon>
    </a>

</template>

<script>
export default {
  name: "btn-arrow",
  props:{
    linkItem:{
      type:Object
    },
    color:{
      type:String,
      default:"#888"
    }
  },
  computed:{
    /**
     *
     * @return {LinkItem}
     */
    link(){
      return this.linkItem;
    },
    toComp(){
      if(this.link){
        return this.link.to;
      }
      return this.href;
    },
    hrefComp(){
      if(this.link){
        return this.link.href;
      }
      return this.href;
    },
    labelComp(){
      if(this.link){
        return this.link.label;
      }
      return this.label;
    },
    targetComp(){
      if(this.link){
        return this.link.target;
      }
      return null;
    }
  }
}
</script>

<style lang="less">

.btn-arrow{
  color: inherit !important;
  border-left: var(--bds) solid currentColor;
  border-bottom: var(--bds) solid currentColor;
  position: relative;

  --bds:3px;
  --px:20px;
  --h:40px;
  --ico:25px;

  @media(min-width: @screen-md){
    --px:2vw;
    --h:3vw;
    --ico:2vw;
  }
  @media(min-width: @screen-lg){
    --h:2.5vw;
  }

  line-height: 1 !important;

  display: inline-flex;
  padding-top: var(--bds);
  padding-left: var(--px);
  padding-right: calc( var(--px) + var(--ico) * 0.25 );
  height: var(--h);
  align-items: center;
  border-radius:  var(--bds);

  >span{
    white-space: nowrap;
  }

  &:before,&:after{
    //border-radius: 3px;
    content: "";
    display: block;
    position: absolute;
    background-color: currentColor;
    border-radius:  var(--bds);

  }
  &:before{
    top: 0;
    left: 0;
    //height: var(--bds);
    border-top: var(--bds) solid currentColor;
    width: calc( 100% - var(--ico) * 0.5 );
  }
  &:after{
    bottom: 0;
    right: 0;
    //width: var(--bds);
    border-right: var(--bds) solid currentColor;
    height: calc( 100% - var(--ico) * 0.5 );
  }
  .ico{
    //outline: var(--bds) solid rgba(255, 0, 0, 0.13);
    position: absolute;

    top:0;
    right: 0;
    font-size: var(--ico) !important;
    transform: translateX(calc( var(--ico) * 0.2 )) translateY(calc( var(--ico) * -0.2 ));
    animation-name: btn-arrow;
    animation-duration: 2s;
    animation-iteration-count: 1;
  }
  &:hover{
    .ico{
      animation-duration: 1s;
      animation-iteration-count: infinite;
    }
  }

  @keyframes btn-arrow{
    0%{
      transform: translateX(0.5vw) translateY(-0.5vw);
    }
    100%{
      transform: translateX(0.2vw) translateY(-0.2vw);
    }
  }

}

</style>