<template>
  <a :is="linkItem.component"
     :to="linkItem.to"
     :href="linkItem.toOrHref"
     :target="linkItem.target"
     :rel="linkItem.target==='_blank'?'noopener':null"
  >
        <slot/>
  </a>
</template>

<script>
import LinkItem from "@/ee09/models/LinkItem";

export default {
  name: "link-item-href",
  props:{
    linkItem:{
      type:LinkItem
    }
  }
}
</script>
