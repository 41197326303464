<template>
<div class="spacing-block"/>
</template>

<script>
import BlockMixin from "@/Models/blocks/BlockMixin";

export default {
  name: "spacing-block",
  components: {},
  mixins:[BlockMixin],
  computed:{},

}
</script>
<style lang="less">
.spacing-block{
  height: 40px;
  @media(min-width: @screen-sm){
    height: 60px;
  }
  @media(min-width: @screen-lg){
    height: 80px;
  }
  @media(min-width: @screen-xl){
    height: 120px;
  }
}
</style>
