<template>
<router-link
    :to="page.href"
    class="slide-page carousel-slide pb-line-2">
  <!--
  <h5 v-if="page.parent.record" class="txt txt-annotation">{{page.parent.record.name}}</h5>
  -->
  <h2 class="txt-label t">{{page.name}}</h2>
</router-link>
</template>

<script>
import RecordMixin from "@/ee09/db-ui/records/RecordMixin";

export default {
  name: "slide-default",
  mixins:[RecordMixin],
  computed:{
    /**
     *
     * @return {PageModel}
     */
    page(){
      return this.recPage;
    }
  }
}
</script>

<style lang="less">
  .slide-page{

  }
</style>