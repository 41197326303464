<template>
<div class="diaporama"
     v-if="fld.items.length>0">
  <swiper class="swiper" ref="swiper" :options="swiperOption">
    <swiper-slide
        v-for="(image,index) of fld.items"
        :key="`slide-${image.uid}-${index}`">
      <ee09-picture
          :key="image.uid"
          :image-field="image.image"
          :xs="600"
          :sm="800"
          :md="800"
          :lg="1200"
          :xl="1500"
      />
    </swiper-slide>


    <div class="swiper-pagination" slot="pagination"></div>

  </swiper>

  <nav class="ty-title-link">

      <i v-if="fld.items.length>1"
         class="prev"
         @click="$refs.swiper.$swiper.slidePrev()"><-</i>
      <i class="zoom"
         @click="zoom()">⊕</i>
      <i v-if="fld.items.length>1"
         class="next"
         @click="$refs.swiper.$swiper.slideNext()">-></i>
  </nav>

</div>
</template>

<script>
import Ee09Picture from "@/components/atoms/ee09-picture";
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
export default {
  name: "diaporama",
  components: {Ee09Picture,    Swiper, SwiperSlide},
  props:{
    field:{
      type:Object
    }
  },
  data() {
    let me=this;
    return {
      isEnd:true,
      isBeginning:true,
      swiper:null,
      swiperOption: {
        speed:500,
        on: {
          'slideChange':function(){

            me.isBeginning=this.isBeginning;
            me.isEnd=this.isEnd;

          },
          'init': function () {
            me.isBeginning=this.isBeginning;
            me.isEnd=this.isEnd;
          },
        },
        //pagination: {el: '.swiper-pagination'},
        //centerInsufficientSlides:true,
        disabledClass: 'disabled_swiper_button',
        watchOverflow:true,
        slidesPerView: 1,
        loop:true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
      }
    }
  },
  mounted(){
    let me=this;
    this.$nextTick(() => {
      me.initSwiper();
    });
  },
  computed:{
    /**
     *
     * @return {ImageItemList}
     */
    fld(){
      return this.field;
    },
    images(){
      let r=[]
      for(let im of this.fld.items){
        r.push(im.image)
      }
      return r;
    }
  },
  methods:{
    zoom(){
      this.$layout.imagesZoom.display(this.images[this.$refs.swiper.$swiper.realIndex ],this.images);
    },
    initSwiper(){
      let me=this;
      setTimeout(() => {
        if(me.hasSwipper){
          me.swiper.update();
        }
      }, 100)
    }
  },
}
</script>

<style lang="less">
@import "../../node_modules/swiper/css/swiper.min.css";
.diaporama{
  position: relative;
  height: 100%;
  .swiper{
    height: 100%;
  }
  .swiper-slide{
  }
  .swiper-button{
    z-index: 2;
    width: auto;
    white-space: nowrap;
    &:after{
      display: none;
    }
    &.swiper-button-prev{
      transform-origin: left;
      text-align: left;
      justify-content: left;

    }
    &.swiper-button-next{
      transform-origin: right;
      text-align: right;
      justify-content: right;
    }

  }

  nav{
    outline-offset: -4px;
    pointer-events: none;
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2vmin;
    .prev{
      margin-left: 0;
      margin-right: auto;
    }
    .next{
      margin-right: 0;
      margin-left: auto;
    }
    >*{
      //outline: 1px solid red;
      transition: all 0.2s;
      cursor: pointer;
      pointer-events: auto;
      &:hover{
        transform: scale(1.1);
        opacity:1 !important;
      }
    }
    .zoom{
      opacity:0.0;
      width: 70%;
      height: 70%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

}
</style>