<template>
<div class="btns-text-block">
  <block-classic-container>
    <v-row class="justify-center">
      <v-col cols="12" md="6">
        <div v-for="btn of blc.itemsOk"
             :key="btn.uid"
             class="mb-2"
        >
          <btn-arrow :link-item="btn"/>
        </div>
      </v-col>
      <v-col cols="12" md="6">
        <div class="txt-paragraph"
              v-html="blc.text"/>
      </v-col>
    </v-row>
  </block-classic-container>
</div>
</template>

<script>
import BlockMixin from "@/Models/blocks/BlockMixin";
import BtnArrow from "@/components/atoms/btn-arrow";
import BlockClassicContainer from "@/Models/blocks/block-classic-container";

export default {
  name: "btns-text-block",
  components: {BlockClassicContainer, BtnArrow},
  mixins:[BlockMixin],
  computed:{
    /**
     *
     * @return {BtnsTextBlock}
     */
    blc(){
      return this.block;
    }
  }
}
</script>

<style lang="less">
.btns-text-block{
}
</style>