<template>
<router-link
    :to="post.href"
    class="slide-post carousel-slide pb-line-2">
  <v-spacer/>
  <v-icon color="#fff" x-large class="ico">iffdec-cta-square</v-icon>
  <v-spacer/>
  <h5 class="txt txt-annotation">{{post.date_published.format("dd / MM / yyyy")}}</h5>
  <h2 class="txt-label t">{{post.name}}</h2>

</router-link>
</template>

<script>
import RecordMixin from "@/ee09/db-ui/records/RecordMixin";

export default {
  name: "slide-post",
  mixins:[RecordMixin],
  computed:{
    /**
     *
     * @return {PostModel}
     */
    post(){
      return this.recPage;
    }
  }
}
</script>

<style lang="less">
  .slide-post{
    .ico{
      transition: all 0.2s;
      opacity: 0;
      transform: scale(0.7);
    }
    &:hover{
      .ico{
        opacity: 1;
        transform: scale(1);
      }
    }
  }
</style>