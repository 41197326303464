<template>
  <media-rounded-container>
    <v-responsive
        :aspect-ratio="258/358">
      <ee09-picture
          :image-field="page.thumbnail"
          xs="225"
          sm="260"
          md="360"
          lg="600"
          xl="600"
      />
      <slide-portrait
          v-if="page.type==='portrait'"
          class="content"
          :record="page" />
      <slide-post
          v-else-if="page.type==='post'"
          class="content"
          :record="page" />
      <slide-default
          v-else
          class="content"
          :record="page" />
    </v-responsive>
  </media-rounded-container>
</template>

<script>
import Ee09Picture from "@/components/atoms/ee09-picture";
import SlidePortrait from "@/Models/blocks/links/carousel-pages/slide-portrait";
import SlidePost from "@/Models/blocks/links/carousel-pages/slide-post";
import SlideDefault from "@/Models/blocks/links/carousel-pages/slide-default";
import MediaRoundedContainer from "@/components/atoms/media-rounded-container";
export default {
  name: "page-card-portrait",
  components: {MediaRoundedContainer, SlideDefault, SlidePost, SlidePortrait, Ee09Picture},
  props:{
    page:{
      type:Object
    }
  }
}
</script>

<style scoped>

</style>