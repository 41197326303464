<template>
<div class="carousel-photos-block">
  <container-grid-size>
    {{blc.blockType}}
  </container-grid-size>
</div>
</template>

<script>
import BlockMixin from "@/Models/blocks/BlockMixin";
import ContainerGridSize from "@/components/atoms/container-grid-size";

export default {
  name: "carousel-photos-block",
  components: { ContainerGridSize},
  mixins:[BlockMixin],
  computed:{
    /**
     *
     * @return {CarouselPhotosBlock}
     */
    blc(){
      return this.block;
    }
  }
}
</script>

<style lang="less">
.carousel-photos-block{

}
</style>