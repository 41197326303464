<template>
  <div class="logo-list">

      <div class="img" v-for="(image,i) of fld.items"
           :key="i">
        <link-item-href :link-item="image.link">
          <img :src="image.image.resize(
                300,300,
                'contain',
                'transparent',
                80,
                'webp')"
          />
        </link-item-href>

      </div>
  </div>
</template>

<script>
import LinkItemHref from "@/components/atoms/link-item-href";
//import ImageItemListField from "@/Models/blocks/edit/image-item-list-field";
export default {
  name: "logo-list",
  components: {LinkItemHref},
  props:{
    fld:{
      type:Object
    }
  }
}
</script>

<style lang="less">
.logo-list{
    display: flex;
    justify-content: left;
    align-items: center;
    flex-wrap: wrap;
    .img{
      //outline: 1px dotted red;
      flex-grow: 0;
      //filter: invert(0.9) saturate(0);
      margin-right: 15px;
      position: relative;
      img{
        max-height: 30px;
        max-width: 100px;
      }
      @media(min-width: @screen-lg){
        img{
          max-height: 40px;
          max-width: 150px;
        }
      }
      &:hover{
        opacity: 0.8;
        //filter: invert(1) saturate(0);
      }
    }

}
</style>