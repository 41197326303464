<template>
<div class="films-block" v-if="blc.isOk">
  <v-container>
    <v-row class="mx-md-n7 mx-lg-n10">
      <v-col v-for="film in blc.films"
             :key="film.uid"
             cols="12"
             class="pa-md-7 pa-lg-10"
             :sm="blc.dense?6:12"
             style="position: relative;"
      >
      <film-item
          :record="film"
          :dense="blc.dense"
      />
      </v-col>
    </v-row>
  </v-container>
</div>
</template>

<script>
import BlockMixin from "@/Models/blocks/BlockMixin";
import FilmItem from "@/Models/blocks/links/films-block/film-item";

export default {
  name: "films-block",
  components: {FilmItem},
  mixins:[BlockMixin],
  computed:{
    /**
     *
     * @return {FilmsBlock}
     */
    blc(){
      return this.block;
    },
  },
}
</script>

<style lang="less">
.films-block{
  .film-item{
    height: 100%;
  }
}
</style>