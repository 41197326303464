<template>
<router-link
    :to="portrait.href"
    class="slide-portrait carousel-slide pb-line-2">
  <h2 class="txt-label t">{{portrait.name}}</h2>
  <h5 class="txt txt-annotation">{{portrait.fonction}}</h5>
</router-link>
</template>

<script>
import RecordMixin from "@/ee09/db-ui/records/RecordMixin";

export default {
  name: "slide-portrait",
  mixins:[RecordMixin],
  computed:{
    /**
     *
     * @return {PortraitModel}
     */
    portrait(){
      return this.recPage;
    }
  }
}
</script>

<style lang="less">
  .slide-portrait.carousel-slide{
    backdrop-filter: saturate(0);
    .t{
      margin-bottom: 0.5em;
    }
  }
</style>