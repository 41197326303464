<template>
  <div class="shadow-hard-1 d-flex media-rounded">
    <slot/>
  </div>
</template>

<script>
export default {
  name: "media-rounded-container"
}
</script>

<style lang="less">
.media-rounded{
    border-radius: 10px !important;
    overflow: hidden;
    >*{
      flex-grow: 1;
    }
}
</style>