<template>
  <v-row>
    <v-col cols="12">
      <v-text-field
          :readonly="!$dbUi.preferences.debugMode"
          :disabled="!$dbUi.preferences.debugMode"
          label=""
          v-model="obj.name"
          placeholder="Identifiant texte de ce champ"
      />
    </v-col>
    <v-col>
      <slot/>
    </v-col>
  </v-row>
</template>

<script>
import BlockMixin from "@/Models/blocks/BlockMixin";

export default {
  name: "named-block-layout",
  mixins:[BlockMixin],
  computed:{
    /**
     *
     * @return {BlockItem}
     */
    obj(){
      return this.block;
    }
  }
}
</script>
