<template>
  <div class="pi-title pt-2">

    <component
        class="titre"
        :is="seo"
        :class="{
          'ty-title-section':big,
          'ty-title-paragraph':medium,
        }">
      <slot/>
    </component>


  </div>
</template>

<script>
export default {
  name: "pi-title",
  components: {},
  props:{
    fat:{
      type:Boolean,
      default:false
    },
    big:{
      type:Boolean,
      default:false
    },
    medium:{
      type:Boolean,
      default:false
    },
    center:{
      type:Boolean,
      default:false
    },
    seo:{
      type:String,
      default: "h1"
    }
  }
}
</script>

<style lang="less">
.pi-title{
}
</style>