<template>
  <swiper
      v-if="blc.pages.records.length>0"
      class="swiper"
      ref="swiper"
      :options="swiperOption">
    <swiper-slide
        v-for="page of blc.pagesList"
        :key="`slide-${page.uid}`"
        class="slide">
      <page-card-portrait :page="page"/>
    </swiper-slide>
    <div class="swiper-button-prev"
         :class="{active:!isBeginning}"
         slot="button-prev"
         @click="$refs.swiper.$swiper.slidePrev()"
    />
    <div class="swiper-button-next"
         :class="{active:!isEnd}"
         slot="button-next"
         @click="$refs.swiper.$swiper.slideNext()"
    />
  </swiper>
</template>

<script>
import PageCardPortrait from "@/Models/blocks/links/carousel-pages/page-card-portrait";
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import BlockMixin from "@/Models/blocks/BlockMixin";
export default {
  name: "carousel-pages",
  mixins:[BlockMixin],
  components: {
    PageCardPortrait,
    Swiper,
    SwiperSlide
  },
  data() {
    let me=this;
    return {
      isEnd:true,
      isBeginning:true,
      swiperOption: {
        on: {
          'slideChange':function(){
            me.isBeginning=this.isBeginning;
            me.isEnd=this.isEnd;
          },
          'init': function () {
            me.isBeginning=this.isBeginning;
            me.isEnd=this.isEnd;
          },
        },
        centerInsufficientSlides:true,
        disabledClass: 'disabled_swiper_button',
        watchOverflow:true,
        slidesPerView: 5,
        spaceBetween: 2,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },

        breakpoints: {
          1900: {
            slidesPerView: 5,
            spaceBetween: 2,
          },
          1200: {
            slidesPerView: 4,
            spaceBetween: 2
          },
          700: {
            slidesPerView: 3,
            spaceBetween: 2
          },
          500: {
            slidesPerView: 2,
            spaceBetween: 2
          },
          0: {
            slidesPerView: 1,
            spaceBetween: 2
          }
        }
      }
    }
  },
  mounted(){
    let me=this;
    if(!this.blc.gridMode){
      this.$nextTick(() => {
        me.initSwiper();
      });
    }
  },
  watch:{
    itemsCount(){
      this.initSwiper();
    },
    gridMode(){
      this.initSwiper();
    }
  },
  methods:{
    initSwiper(){
      let me=this;
      setTimeout(() => {
        if(me.hasSwipper){
          me.swiper.update();
        }
      }, 100)
    }
  },
  computed:{
    itemsCount(){
      return this.blc.pagesList.length;
    },
    gridMode(){
      return this.blc.gridMode;
    },
    hasSwipper(){
      return this.blc.pagesList.length>0
    },
    /**
     *
     * @return {CarouselPagesBlock}
     */
    blc(){
      return this.block;
    },
    swiper() {
      return this.$refs.swiper.$swiper;
    }
  }
}
</script>

<style scoped>

</style>