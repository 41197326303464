<template>
  <container-grid-size :class="containerClass">
    <v-row>
      <v-col :lg="width.lg" :offset-lg="width.offsetLg">
        <slot/>
      </v-col>
    </v-row>
  </container-grid-size>
</template>

<script>
/**
 * Beaucoup de blocks sont callé sur une grille de 10 colonnes en HD
 * Ce container correspond à ça et évite de dupliquer du code
 */
import ContainerGridSize from "@/components/atoms/container-grid-size";
export default {
  name: "block-classic-container",
  components: {ContainerGridSize},
  props:{
    containerClass:{
      type:String
    }
  },
  computed:{
    /**
     * La largeur de ce container peut varier en fonction du type de page
     * no comment :\
     * @return {{lg: string, offsetLg: string}}
     */
    width(){
      let r={
        "lg":"10",
        "offsetLg":"1"
      }
      if(this.$layout.currentPage.type==="page"){
        r.lg="12";
        r.offsetLg="0";
      }


      return r;
    }
  }
}
</script>

<style scoped>

</style>