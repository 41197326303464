<template>
<div class="locations-block" v-if="blc.isOk">
  <v-container>
    <v-row class="mx-md-n7 mx-lg-n10">
      <v-col v-for="location in blc.locations"
             :key="location.uid"
             cols="12"
             class="pa-md-7 pa-lg-10"
             :sm="blc.dense?6:12"
             style="position: relative;"
      >
      <location-item
          :record="location"
          :dense="blc.dense"
      />
      </v-col>
    </v-row>
  </v-container>
</div>
</template>

<script>
import BlockMixin from "@/Models/blocks/BlockMixin";
import LocationItem from "@/Models/blocks/links/locations-block/location-item";

export default {
  name: "locations-block",
  components: {LocationItem},
  mixins:[BlockMixin],
  computed:{
    /**
     *
     * @return {LocationsBlock}
     */
    blc(){
      return this.block;
    },
  },
}
</script>

<style lang="less">
.locations-block{
  .location-item{
    height: 100%;
  }
}
</style>