<template>
  <div class="accordion-block has-href-underline"
       :data-open="open"
       :id="blc.uid">
    <v-container>
      <v-row class="toggler py-lg-10"
             align="baseline"
             @click="open=!open"
      >
        <v-col cols="1" class="ty-rich-text">{{blc.prefix}}</v-col>
        <v-col class="ty-title-link like-a u-span">
          <span class="">{{blc.title}}</span>
        </v-col>
        <v-col cols="3" class="text-right">
          <v-icon size="40" color="var(--color-project)">{{icon}}</v-icon>
        </v-col>
      </v-row>
      <v-row class="content">
        <v-col cols="12"
               md="6"
               class="pa-0">
          <pic-iframe
              v-if="blc.mediaType==='iframe'"
              :block="blc"
              class="media"
          />
          <diaporama
              v-if="blc.mediaType==='photos'"
              :field="blc.photos"
              class="media"
          />
        </v-col>
        <v-col cols="12"
               md="6"
               class="py-lg-15 px-lg-16 d-flex flex-column justify-center">
          <div v-html="blc.legende" class="ty-rich-text"/>

          <logo-list
              v-if="blc.logos.items.length"
              class="mt-10"
              :fld="blc.logos"/>

        </v-col>
      </v-row>
    </v-container>
  </div>

</template>

<script>
import BlockMixin from "@/Models/blocks/BlockMixin";
import PicIframe from "@/pic/pic-iframe";
import LogoList from "@/pic/logo-list";
import Diaporama from "@/pic/diaporama";

export default {
  name: "accordion-block",
  components: {Diaporama, LogoList, PicIframe},
  mixins:[BlockMixin],
  data(){
    return{
      open:false
    }
  },
  computed:{
    icon(){
      return this.open?this.$icons.close:this.$icons.plus;
    },
    /**
     *
     * @return {AccordionBlock}
     */
    blc(){
      return this.block;
    }
  },
  watch:{ }
}
</script>

<style lang="less">
.accordion-block{
  .toggler{
    user-select: none;
    cursor: pointer;
    --href-underline-top: 100%;
  }
  .content{
    transition: all 0.5s;
    transition-timing-function: ease-out;
    //border: 0 solid transparent;
    border: 1px solid #fff;
    border-top-width: 0px;
    max-height: 0;
    overflow-y: hidden;
    .media{
      .video-ratio{
        height: 100%;
      }
      height: calc( 100vw * 0.7 );
      @media(min-width: @screen-md){
        height: calc( (840px/2) * 0.7 );
      }
      @media(min-width: @screen-lg){
        height: calc( (1180px/2) * 0.7 );
      }
      @media(min-width: @screen-xl){
        height: calc( (1440px/2) * 0.7 );
      }
    }
  }
  &[data-open]{
    .content{
      transition-timing-function: ease-in;
      border-top-width: 1px;
      max-height: 1000px;
    }
    iframe{
      background-color: #000;
    }
  }
}
</style>