<template>
<div class="logo-list-block ">
  <content-container :full-height="false">
        <div class="img"
               v-for="(image) of blc.images.items"
               :key="image.uid"
        >
          <link-item-href :link-item="image.link">
            <img :src="image.image.resize(
                300,300,
                'contain',
                'transparent',
                80,
                'webp')"
            />
          </link-item-href>

        </div>
  </content-container>
</div>
</template>

<script>
import BlockMixin from "@/Models/blocks/BlockMixin";
import ContentContainer from "@/pic/content-container";
import LinkItemHref from "@/components/atoms/link-item-href";

export default {
  name: "logo-list-block",
  components: {LinkItemHref, ContentContainer},
  mixins:[BlockMixin],
  computed:{
    /**
     *
     * @return {LogoListBlock}
     */
    blc(){
      return this.block;
    }
  }
}
</script>

<style lang="less">
.logo-list-block{
  .content-container{
    display: flex;
    justify-content: center;
    align-items: center;
    .img{
      flex-grow: 0;
      filter: invert(1) saturate(0);
      //max-width: 150px;
      margin: 0 15px 15px 0;
      position: relative;
      img{
        max-height: 60px;
        max-width: 150px;
      }
      &:hover{
        filter: invert(1) saturate(1);
      }
    }
  }

}

</style>