<template>
<div class="py-2 icones-liens-block">
  <v-container>
    <v-row>
      <v-col class="ty-rich-text" cols="12" sm="6">
        <h2>
          fiche technique <span class="text-no-wrap" style="color:var(--color-project)">-></span>
        </h2>
      </v-col>
      <v-col class="logos d-flex flex-wrap" cols="12" sm="6">
        <template v-for="(item,i) of ['imdb','allocine','unifrance','youtube','vimeo']">
          <a :href="blc[item]"
             target="_blank"
             :key="i"
             v-if="blc[item]">
            <img :src="`${$layout.publicPath}logos/${item}.svg`"
                 :alt="item"/>
          </a>
        </template>
      </v-col>
    </v-row>
  </v-container>
</div>
</template>

<script>


import BlockMixin from "@/Models/blocks/BlockMixin";

export default {
  name: "icones-liens-block",
  components: {

  },
  mixins:[BlockMixin],
  computed:{
    /**
     *
     * @return {IconesLiensBlock}
     */
    blc(){
      return this.block
    },
  },

}
</script>
<style lang="less">
.icones-liens-block{
  .logos{
    img{
      height: 45px;
      margin: 0 40px 20px 0;
      max-width: 150px;
    }
    >*{
      //outline: 1px solid red;

    }
    @media(hover: hover){
      &:hover{
        >*{
          opacity: 0.5;
          &:hover{
            opacity: 1;
          }
        }

      }
    }
  }
}
</style>
