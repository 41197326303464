<template>
  <div>
    <v-container>
      <diaporama :field="blc.photos"
                 class="media" />
    </v-container>
  </div>

</template>

<script>
import BlockMixin from "@/Models/blocks/BlockMixin";
import Diaporama from "@/pic/diaporama";

export default {
  name: "diaporama-block",
  components: {Diaporama},
  mixins:[BlockMixin],
  data(){
    return{ }
  },
  computed:{
    /**
     *
     * @return {DiaporamaBlock}
     */
    blc(){
      return this.block;
    }
  },
  watch:{ }
}
</script>
