export default {
    data: () => ({
        isIntersecting: false,
    }),
    methods: {
        // eslint-disable-next-line no-unused-vars
        onIntersect (entries, observer) {
            // More information about these options
            // is located here: https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API
            this.isIntersecting = entries[0].intersectionRatio >= 0.5
            if(this.isIntersecting){
                this.$layout.elementInViewPort=this.$el;
            }
        },
        onIntersectLarge(entries){
            this.isIntersecting = entries[0].intersectionRatio >= 0.1
        },
        onIntersectTop(entries){
            this.isIntersecting = entries[0].intersectionRatio >= 0.1
        }
    },
    computed:{
        intersectTop(){
            return {
                handler: this.onIntersectTop,
                options: {
                    threshold:[0, 0.5, 1.0]
                }
            }
        },
        defaultIntersect(){
            return {
                handler: this.onIntersect,
                options: {
                    threshold: [0, 0.5, 1.0]
                }
            }
        },
        intersectLarge(){
            return {
                handler: this.onIntersectLarge,
                options: {
                    threshold:[0, 0.5, 1.0]
                }
            }
        }
    },
    watch:{

    }
}